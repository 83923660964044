import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";

import axios from '../axios';
import AlertsTable from "../cmp/AlertsTable";
import ErrorModal from "../cmp/ErrorModal"

function Alerts(props) {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState();
    const [alertsRecords, setAlertsRecords] = React.useState(null);

    const getAlertsRecords = () => {
        axios.get("/user/alerts")
            .then(response => {
                if (response.data.status === "ok") {
                    setAlertsRecords(response.data.alerts)
                }
                else {
                    setMessage("Could not load data!")
                    setOpen(true)
                }
            })
            .catch(err => console.log(err));
    }

    return (
        <>
            <div style={{ paddingLeft: '225px', paddingTop: '40px', paddingRight: '40px' }}>
                <Typography variant="h4" style={{ paddingBottom: '15px' }}><b>Alerts</b></Typography>
                
            </div>

        <Grid container style={{ paddingLeft: '225px', paddingTop: '15px', paddingRight: '40px' }}>
                <AlertsTable sipInfo={alertsRecords} onRefresh={getAlertsRecords} />
        </Grid>
       
        <ErrorModal open={open} setOpen={setOpen} message={message} />
    </>
);
}

export default Alerts;