import React, { useEffect, useLayoutEffect } from "react";
import ReactDOM from 'react-dom';
import axios from "../axios";
import Datatable from "../cmp/Datatable";
import FilterSelect from "../cmp/FilterSelect";
import ErrorModal from "../cmp/ErrorModal";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Grid, Paper, Typography, Button } from '@mui/material';
import { Card, LineChart, BarChart, List, ListItem,TooltipProps, AreaChart, Col, Title } from '@tremor/react';
import KpiCard from '../cmp/KpiCard/KpiCard';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';

const kpis = [
  {
    title: 'MOS',
    metric: '$ 12,699',
    progress: 15.9,
    metricTarget: '$ 80,000',
    delta: '13.2%',
    deltaType: 'moderateIncrease',
  },
  {
    title: 'Jitter',
    metric: '$ 45,564',
    progress: 36.5,
    metricTarget: '$ 125,000',
    delta: '23.9%',
    deltaType: 'increase',
  },
  {
    title: 'Latency',
    metric: '1,072',
    progress: 53.6,
    metricTarget: '2,000',
    delta: '10.1%',
    deltaType: 'moderateDecrease',
  },
]

let chartdata = [
];
// const [chartData, setChartData] = React.useState([]);
const dataFormatter = (number) =>
  `${Intl.NumberFormat('us').format(number).toString()}`;

//trmer end

const monthFilters = {
  1: 'Last 1 Month',
  3: 'Last 3 Months',
  6: 'Last 6 Months'
};

const mosFilters = {
  5: 'Filter MOS',
  4: 'MOS < 4',
  3: 'MOS < 3',
  2: 'MOS < 2',
  1: 'MOS < 1'
};

const latencyFilters = {
  80: 'Filter Latency',
  70: 'Latency < 70',
  60: 'Latency < 60',
  50: 'Latency < 50',
  40: 'Latency < 40',
  30: 'Latency < 30',
  20: 'Latency < 20',
  10: 'Latency < 10'
};

const ModalDataDisplay = ({ dataItem, handleClose, handleCancel }) => {
  return (
    <div>
      <Typography variant="h2" gutterBottom>
        Test Call Details
      </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: '16px' }}>
          <Typography variant="h6" gutterBottom>
            Data Overview
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle1">Date:</Typography>
              <Typography variant="body1">{dataItem.timestamp}</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle1">MOS:</Typography>
              <Typography variant="body1">{dataItem.mos}</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle1">Jitter (max):</Typography>
              <Typography variant="body1">{dataItem.s.jitter_max}</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle1">Jitter (mittel):</Typography>
              <Typography variant="body1">{dataItem.jitter}</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle1">Jitter (min):</Typography>
              <Typography variant="body1">{dataItem.s.jitter_min}</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle1">Pakete (verloren):</Typography>
              <Typography variant="body1">{dataItem.lostpackets}</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle1">Pakete (verloren) percent:</Typography>
              <Typography variant="body1">{dataItem.lostpercent}</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle1">Codec:</Typography>
              <Typography variant="body1">{dataItem.codec}</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle1">iperf:</Typography>
              <Typography variant="body1">{dataItem.iperf}</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle1">Details:</Typography>
              <Typography variant="body1">{dataItem.detail}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
    <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" color="primary" onClick={handleClose} style={{ marginRight: '8px' }}>
          OK
        </Button>
        <Button variant="contained" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};


function Results() {

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  
  
  
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const [selectedValues, setSelectedValues] = React.useState({
    month: '1',
    mos: '5',
    latency: '80'
  });

  const handlefilterChange = (event) => {
    const { name, value } = event.target;
    setSelectedValues({
      ...selectedValues,
      [name]: value
    });
    setFetchAttempted(false);
  };

  const [reqData, setReqData] = React.useState([]);
  const [fetchAttempted, setFetchAttempted] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState();
  

  useEffect(() => {
    const fetchData = () => {
      axios.get(`${process.env.REACT_APP_API_URL}/testcalls`, {
        params: {
          month: selectedValues.month,
          mos: selectedValues.mos,
          latency: selectedValues.latency
        }
      })
        .then((response) => {
          if (response.data.status === "ok") {
          let newData = response.data.testcalls.map(item => {
            var date1 = new Date();
            am5.time.add(date1, "hour", Math.floor(Math.random() * 8));
            let date = new Date(item.tstamp);
            let hours = date.getHours();
            let amOrPm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12;
            let formattedTime = `${hours} ${amOrPm}`;
            date1= new Date(item.tstamp).getTime();
        
            return {
                ...item,
                timestamp: formattedTime,
                t1: date1,
                latency_mean: item.r.latency_mean,
            };
        });

        chartdata = response.data.testcalls.map(item => {
          let date = new Date(item.tstamp);
          let month = date.toLocaleString('default', { month: 'short' });
          let year = date.getFullYear().toString().slice(-2);
          let datenumber = date.getDate().toString().padStart(2, '0');
          let hours = date.getHours();
          let formattedDate = `${datenumber}-${month}-${year}:${hours}h`;

          return {
            date: formattedDate,
            MOS: item.mos, 
            Jitter: item.jitter, 
            Latency: item.r.latency_mean 
          };
        })
        chartdata.sort((a, b) => a.date - b.date);

        newData.sort((a, b) => a.t1 - b.t1);
          console.log(newData)
            setReqData(newData);
          } else {
            setMessage("Could not load data!");
            setOpen(true);
          }
        })
        .catch((err) => console.log(err));
    };

    if (!fetchAttempted) {
      setFetchAttempted(true);
      fetchData();
    }
  }, [fetchAttempted , selectedValues]);

  useLayoutEffect(() => {

    let mosroot = am5.Root.new("moschartdiv");
         // Create a new div for the second chart with a unique ID
    let jitterChartDiv = document.createElement("div");
    jitterChartDiv.id = "jitterchart-container";
    document.getElementById("moschartdiv").appendChild(jitterChartDiv);

    // Create the root for the second chart
    let jitterroot = am5.Root.new(jitterChartDiv);

    let modal = am5.Modal.new(mosroot, {
      content: "<h2>Test Call Details</h2>"
    });

    var modalSetup = false;

    const openModal = (dataItem) => {
      modal.getPrivate("content").innerHTML = "";
      modalSetup = false;

      const valueElement = document.createElement("div");
      modal.getPrivate("content").appendChild(valueElement);
      const handleClose = () => {
        modal.close();
      };

      const handleCancel = () => {
        modal.cancel();
      };
      ReactDOM.render(<ModalDataDisplay 
      dataItem={dataItem} 
      handleClose={handleClose}
      handleCancel={handleCancel}
      />, valueElement);
      modal.open();
    };
    
    const closeModal = () => {
      if (modal) {
        modal.close();
      }
    };
    
    
    if (reqData.length > 0) {
      document.getElementById("no-data").style.display = "none";
    mosroot.setThemes([am5themes_Animated.new(mosroot)]);
    let mosexporting = am5plugins_exporting.Exporting.new(mosroot, {
      menu: am5plugins_exporting.ExportingMenu.new(mosroot, {}),
    });

    let moschart = mosroot.container.children.push(
      am5xy.XYChart.new(mosroot, {
        focusable: true,
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        exportable: true,
        title: "Your Chart Title",
      })
    );

    moschart.children.unshift(am5.Label.new(mosroot, {
      text: "MOS",
      fontSize: 25,
      fontWeight: "500",
      textAlign: "center",
      x: am5.percent(50),
      centerX: am5.percent(50),
      paddingTop: 20,
      paddingBottom: 0
    }));

    
      setupChart(moschart, reqData,"mos",openModal);
    



  let jittermodal = am5.Modal.new(jitterroot, {
    content: "<h2>Test Call Details</h2>"
  });

  var jittermodalSetup = false;

  const openJitterModal = (dataItem) => {
    jittermodal.getPrivate("content").innerHTML = "";
    jittermodalSetup = false;

    const valueElement = document.createElement("div");
    jittermodal.getPrivate("content").appendChild(valueElement);
    const handleClose = () => {
      jittermodal.close();
    };

    const handleCancel = () => {
      jittermodal.cancel();
    };
    ReactDOM.render(<ModalDataDisplay 
    dataItem={dataItem} 
    handleClose={handleClose}
    handleCancel={handleCancel}
    />, valueElement);
    jittermodal.open();
  };

  jitterroot.setThemes([am5themes_Animated.new(jitterroot)]);
  let jitterrootExport = am5plugins_exporting.Exporting.new(jitterroot, {
    menu: am5plugins_exporting.ExportingMenu.new(jitterroot, {}),
  });

  
  // Create the second chart within the new div
  let jitterchart = jitterroot.container.children.push(
    am5xy.XYChart.new(jitterroot, {
      focusable: true,
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      pinchZoomX: true,
      exportable: true,
    })
  );

  console.log(jitterchart);

  jitterchart.children.unshift(am5.Label.new(jitterroot, {
    text: "Jitter / Latenz",
    fontSize: 25,
    fontWeight: "500",
    textAlign: "center",
    x: am5.percent(50),
    centerX: am5.percent(50),
    paddingTop: 20,
    paddingBottom: 0
  }));

    setupChart(jitterchart, reqData , "jitter",openJitterModal);


  } else {
    console.log("no data");
    mosroot.dispose();
    jitterroot.dispose();
    document.getElementById("no-data").style.display = "block";
  }
    

    
    return () => {
      mosroot.dispose();
      jitterroot.dispose();
    };
  }, [reqData]);

  // Function to add common configuration for the charts
function setupChart(chart, data , seriesType , openModal) {
  const cursor = chart.set(
    "cursor",
    am5xy.XYCursor.new(chart.root, {
      behavior: "none",
    })
  );
  cursor.lineY.set("visible", false);

  let scrollbarX = chart.set(
    "scrollbarX",
    am5xy.XYChartScrollbar.new(chart.root, {
      orientation: "horizontal",
      height: 50,
    })
  );

  var sbxAxis = scrollbarX.chart.xAxes.push(
    am5xy.GaplessDateAxis.new(chart.root, {
      baseInterval: { timeUnit: "minute", count: 1 },
      renderer: am5xy.AxisRendererX.new(chart.root, {
        opposite: false,
        minorGridEnabled: true,
        minGridDistance: 100,
        strokeOpacity: 0
      })
    })
  );
  
  var sbyAxis = scrollbarX.chart.yAxes.push(
    am5xy.ValueAxis.new(chart.root, {
      renderer: am5xy.AxisRendererY.new(chart.root, {})
    })
  );
  
  var sbseries = scrollbarX.chart.series.push(
    am5xy.LineSeries.new(chart.root, {
      xAxis: sbxAxis,
      yAxis: sbyAxis,
      valueYField: "value",
      valueXField: "date"
    })
  );
  sbseries.data.setAll(reqData);



  let scrollbar = chart.set(
    "scrollbarY",
    am5.Scrollbar.new(chart.root, {
      orientation: "vertical",
    })
  );

  let startLabel = scrollbar.startGrip.children.push(am5.Label.new(chart.root, {
    isMeasured: false,
    width: 100,
    fill: am5.color(0x000000),
    centerX: 50,
    centerY: 30,
    // x: am5.p50,
    // y: 0,
    textAlign: "center",
    populateText: true
  }))
  
  scrollbar.on("end", function(position) {
    setTimeout(function() {
      startLabel.set("text", position, "")
    }, 50);
  });
  
  var endLabel = scrollbar.endGrip.children.push(am5.Label.new(chart.root, {
    isMeasured: false,
    width: 100,
    fill: am5.color(0x000000),
    centerX: 50,
    centerY: 30,
    // x: am5.p50,
    // y: 0,
    textAlign: "center",
    populateText: true
  }))
  
  scrollbar.on("start", function(position) {
    setTimeout(function() {
      endLabel.set("text", position, "cm")
    }, 50);
  });

  // Create Y-axis
  let yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(chart.root, {
      maxDeviation: 0.5,
      renderer: am5xy.AxisRendererY.new(chart.root, {}),
      extraMin: 0.1, // Adds an extra 10% space at the bottom
      extraMax: 0.1  // Adds an extra 10% space at the top
    })
  );

  let xAxis = chart.xAxes.push(
    am5xy.DateAxis.new(chart.root, {
      // groupData: true,
      baseInterval: { timeUnit: "minute", count: 1 },
      renderer: am5xy.AxisRendererX.new(chart.root, {
        minorGridEnabled: true,
        minGridDistance: 100,
        strokeOpacity: 0
      }),
    })
  );

  let series;
  if (seriesType === "mos") {
    series = chart.series.push(
      am5xy.LineSeries.new(chart.root, {
        name: "MOS",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "mos",
        valueXField: "t1",
        legendValueText: "{valueY}",
            tooltip: am5.Tooltip.new(chart.root, {
                pointerOrientation: "horizontal",
                labelText: "{name} {valueY} ",
            }),
      })
    );

    // Format the date values on the x-axis
    // xAxis.dateFormatter.inputDateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"; // Adjust format according to your data
    // xAxis.dateFormatter.dateFormat = "HH:mm:ss"; // Adjust format according to your preference
    createRange(4, 5, am5.color(0x297373), yAxis, chart);
    createRange(3, 4, am5.color(0xe9e014), yAxis, chart);
    createRange(0, 3, am5.color(0xff621f), yAxis, chart);
  } else if (seriesType === "jitter") {
    series = chart.series.push(
      am5xy.LineSeries.new(chart.root, {
        name: "Jitter",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "jitter",
        valueXField: "t1",
        legendValueText: "{valueY}",
        tooltip: am5.Tooltip.new(chart.root, {}),
        stroke: am5.color(0x000000), 
        strokeWidth: 7,
      })
    );
    series.get("tooltip").label.set("text", "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}");
    let latencySeries;
    latencySeries = chart.series.push(
      am5xy.LineSeries.new(chart.root, {
        name: "Latenz",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "latency_mean",
        valueXField: "t1",
        tooltip: am5.Tooltip.new(chart.root, {}),
        stroke: am5.color(0xff0000), 
        strokeWidth: 5,              
        strokeDasharray: [2, 2],     
      })
    );
    latencySeries.data.setAll(data);
    latencySeries.bullets.push(function () {
      var circle = am5.Circle.new(chart.root, {
         radius: 5,
         fill: latencySeries.get("stroke"),
         stroke: chart.root.interfaceColors.get("background"),
         strokeWidth: 2,
       });
     circle.events.on("click", function(ev) {
       console.log(ev.target.dataItem.dataContext);
       openModal(ev.target.dataItem.dataContext);
     });
     return am5.Bullet.new(chart.root, {
       sprite: circle
     });
   });
    createRange(40, 80, am5.color(0xff621f), yAxis, chart);
    createRange(20, 40, am5.color(0xe9e014), yAxis, chart);
    createRange(-10, 20, am5.color(0x297373), yAxis, chart);
  }

  xAxis.set("tooltip", am5.Tooltip.new(chart.root, {
    themeTags: ["axis"]
  }));
  
  yAxis.set("tooltip", am5.Tooltip.new(chart.root, {
    themeTags: ["axis"]
  }));

  series.data.setAll(data);
  series.bullets.push(function () {
     var circle = am5.Circle.new(chart.root, {
        radius: 5,
        fill: series.get("stroke"),
        stroke: chart.root.interfaceColors.get("background"),
        strokeWidth: 2,
      });
    circle.events.on("click", function(ev) {
      console.log(ev.target.dataItem.dataContext);
      openModal(ev.target.dataItem.dataContext);
    });
    return am5.Bullet.new(chart.root, {
      sprite: circle
    });
  });

  

  // Add legend
  let legend = chart.children.push(am5.Legend.new(chart.root, {}));
  legend.data.setAll(chart.series.values);

  // Add cursor
  chart.set("cursor", am5xy.XYCursor.new(chart.root, {}));

  // Function to zoom to the last 6 months
function zoomToLastSixMonths() {
  var endDate = new Date();
  var startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 4000);

  xAxis.set("start", xAxis.dateToPosition(startDate));
  xAxis.set("end", xAxis.dateToPosition(endDate));
}
// Call the function to zoom to the last 6 months
// zoomToLastSixMonths();

}

// Create axis ranges
function createRange(value, endValue, color, yAxis, chart) {
  var rangeDataItem = yAxis.makeDataItem({
    value: value,
    endValue: endValue
  });
  
  var range = yAxis.createAxisRange(rangeDataItem);
  
  if (endValue) {
    range.get("axisFill").setAll({
      fill: color,
      fillOpacity: 0.2,
      visible: true
    });
    
    range.get("label").setAll({
      fill: am5.color(0xffffff),
      text: value + "-" + endValue,
      location: 1,
      background: am5.RoundedRectangle.new(chart.root, {
        fill: color
      })
    });
  }
  else {
    range.get("label").setAll({
      fill: am5.color(0xffffff),
      text: value,
      background: am5.RoundedRectangle.new(chart.root, {
        fill: color
      })
    });
  }

  range.get("grid").setAll({
    stroke: color,
    strokeOpacity: 1,
    location: 1
  });
  
}

function formatTimestampToTime(timestamp) {
  // Convert timestamp to a Date object
  const date = new Date(timestamp);

  // Get hours and minutes from the Date object
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine if it's AM or PM
  const ampm = hours >= 12 ? 'pm' : 'am';

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Construct the formatted time string
  const formattedTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;

  return formattedTime;
}

const testsampledata = [
  { time: '2024-07-01', mos: 4.5, jitter: 20, latency: 150 },
  { time: '2024-07-02', mos: 4.3, jitter: 25, latency: 145 },
];

const bardatadata = [
  { provider: 'Provider A', avg_mos: 4.2 },
  { provider: 'Provider B', avg_mos: 3.8 },
];

  return (
    <>
      {/* <div className="col-md-12">

      

        <div
          id="sssss"
          style={{ width: "100%", height: "800px" }}
        >
       
        </div>
      </div> */}


      
      <div
        className="container"
        style={{ paddingLeft: "225px", paddingRight: "25px", marginTop: "20px", marginBottom: "20px",display: "block" }}
      >
        <h1 style={{ marginLeft: "50px", marginRight: "5px", fontSize: "40px" }}>Ergebnisse</h1>

       

        {/* <Charts data={reqData} /> */}
        <Datatable data={reqData} />
        
        <div className="container">
          <div className="row col-md-12" style={{ marginTop: "20px", marginBottom: "20px" , justifyContent: "center", alignItems: "center", flexWrap: "wrap", marginLeft: "auto", display: "flex" }}>
            <div className="col-md-4">
              {/* <FilterSelect label={"Select Month"} values={monthFilters} selectedValue={selectedValues.month} handleChange={handlefilterChange} name="month" /> */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Select Date"
                  views={['year', 'month']}
                  value={selectedValues.month}
                  onChange={(newValue) => handlefilterChange({ target: { name: 'month', value: newValue } })}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
                          </div>
            <div className="col-md-4">
              <FilterSelect label={"Select MoS"} values={mosFilters} selectedValue={selectedValues.mos} handleChange={handlefilterChange} name="mos" />
            </div>
            <div className="col-md-4">
              <FilterSelect label={"Select Latency"} values={latencyFilters} selectedValue={selectedValues.latency} handleChange={handlefilterChange} name="latency" />
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div id="no-data" className="d-none" style={{ width: "100%", fontSize: "xxx-large", textAlign: "center", display: "none", justifyContent: "center", alignItems: "center" }}> <h1>No data present</h1> </div>
          <div
            id="moschartdiv"
            style={{ width: "100%", height: "800px" }}
          ></div>
        </div>
       
        

        
        
      </div>
      <div className="container col-md-12" style={{ paddingLeft: "225px", paddingRight: "25px",display: "block" }} >
       {/* <Grid numCols={1} numColsSm={2} numColsLg={3} className="my-8 gap-6">
        {kpis.map((kpi, i) => (
          <Col key={i} numColSpan={1}>
            <KpiCard kpi={kpi} />
          </Col>
        ))}
       <Col numColSpan={1} numColSpanLg={3}>
          <Card>
            <Title>MOS over time</Title>
            <BarChart 
              data={chartdata} 
              index="date"
              categories={['MOS']}
              colors={['yellow']}
              valueFormatter={dataFormatter}  
              />
          </Card>
        </Col>
      </Grid> */}
      {/* <AreaChart
              className="h-80"
              data={chartdata}
              index="date"
              categories={['MOS']}
              colors={['yellow']}
              valueFormatter={dataFormatter}
              yAxisWidth={60}
              onValueChange={(v) => console.log(v)}
            />

        <AreaChart
              className="h-80"
              data={chartdata}
              index="date"
              categories={['Jitter', 'Latency']}
              colors={['indigo', 'rose']}
              valueFormatter={dataFormatter}
              yAxisWidth={60}
              onValueChange={(v) => console.log(v)}
            /> */}

       </div>
      <ErrorModal open={open} setOpen={setOpen} message={message} />
    </>
    
  );
}

export default Results;
