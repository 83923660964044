import * as React from 'react';
import { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from '../axios';
import { DeleteForever } from '@mui/icons-material';
import DeleteModal from './DeleteModal';

  const AlertsTable = ({ sipInfo, onRefresh }) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (typeof onRefresh === 'function') {
      onRefresh();
    }
  }, []);

  return (
    <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: '100%' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><b>Type</b></TableCell>
            <TableCell><b>Message</b></TableCell>
            <TableCell><b>Time</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sipInfo && sipInfo.map((row, i) => (
            <TableRow
              key={i}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.type}
              </TableCell>
              <TableCell>{row.message}</TableCell>
              <TableCell>{ new Date(row.timestamp).toLocaleString() }</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}

export default AlertsTable;